// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context('../images', true)

import "plugins/bootstrap/bootstrap.min.css";
import "plugins/owl-carousel/owl.carousel.min.css";
import "plugins/owl-carousel/owl.theme.default.min.css";
import "plugins/magnific-popup/magnific-popup.min.css";
import "plugins/sal/sal.min.css";
import "plugins/font-awesome/css/all.css";
import "plugins/themify/themify-icons.min.css";
import "plugins/simple-line-icons/css/simple-line-icons.css";
import 'src/stylesheets/application'



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
